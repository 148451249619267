import 'regenerator-runtime/runtime'

import { Provider as ReduxStoreProvider } from 'react-redux'
import getConfig from 'next/config'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import PropTypes from 'prop-types'

import ExternalAdsProvider from '@hmn/rtl-web-core/components/Ads/External/ExternalAds.provider'
import { TrackingScripts } from '@hmn/rtl-web-core/components/analytics/TrackingScripts'
import { AdCallbacksContext } from '@hmn/rtl-web-core/context/ads/callback.context'
import { FilledSlotsContext } from '@hmn/rtl-web-core/context/ads/filled.context'
import { AdManifestContext } from '@hmn/rtl-web-core/context/ads/manifest.context'
import { RefreshSlotsContext } from '@hmn/rtl-web-core/context/ads/refresh'
import { ClientNavigationContextProvider } from '@hmn/rtl-web-core/context/clientNavigation'
import { CountryCodeProvider } from '@hmn/rtl-web-core/context/CountryCode.context'
import { NavigationContext } from '@hmn/rtl-web-core/context/navigation'
import { LunaContext, SettingsProvider, UpScoreContextProvider } from '@hmn/rtl-web-core/controllers'
import AdsProvider from '@hmn/rtl-web-core/controllers/AdsProvider/Ads.provider'
import { combineWrappers } from '@hmn/rtl-web-core/controllers/combineWrappers'
import { DidomiProvider } from '@hmn/rtl-web-core/controllers/DidomiProvider'
// import { UserAgent, UserAgentHistoryTrap } from '@hmn/rtl-web-core/controllers/UserAgentHistoryTrap'
import pageVariants from '@hmn/rtl-web-core/helpers/pageVariants'
import { getBaseApiURL, useActiveCategory } from '@hmn/rtl-web-core/hooks'
import { IsolatedAppHooks } from '@hmn/rtl-web-core/hooks/useAppHooks'
import { IsolatedUseLengthScoreGTM } from '@hmn/rtl-web-core/hooks/useGTM'
import { QueryCacheProvider } from '@hmn/rtl-web-core/queries'
import { store } from '@hmn/rtl-web-core/store/store'
import { AppName } from '@hmn/rtl-web-core/types/app'

import { GlobalStyles } from '@hmn/rtl-net-ui/components/GlobalStyles'
import { DanasHrLogoGradient } from '@hmn/rtl-net-ui/components/Icon/icons/netLogo/DanasHrLogo'
import { NetLuna as Luna } from '@hmn/rtl-net-ui/controllers/NetLuna'
import { themes } from '@hmn/rtl-net-ui/theme'

import dataProvider, { AwsS3StorageService } from '@hmn/data-provider'

import 'swiper/swiper-bundle.css'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-ads/dist/videojs.ads.css'
import 'videojs-ima/dist/videojs.ima.css'
import '../styles/rtl-video-js.css'
import '../styles/fallbackFonts.css'
import 'survey-core/defaultV2.min.css'
// import { PredsjednickiIzboriContextProvider } from '../components/izbori/2024-predsjednicki/PredsjIzboriContext'
// import TaboolaScript from '../components/Taboola.component'
import SEO from '../next-seo.config'

const { publicRuntimeConfig = {} } = getConfig()
const { xClientName } = publicRuntimeConfig

// Override default theme here, this can be exposed through state or API
const currentTheme = 'net'

AwsS3StorageService.init({ fetch })

dataProvider.init({
    baseUrl: getBaseApiURL({ api: 'human', externalOnly: true }),
    storageService: AwsS3StorageService,
    token: process.env.NEXT_PUBLIC_HUMAN_API_READONLY_TOKEN_NET,
    language: 'hr'
})

const themesObject = { themes, isNet: true }

const ignoreZonesOnShallowNavigationConfig = {
    article: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true,
        'D - Billboard - top': true,
        'M - Billboard - top': true,
        'D - HC - Billboard - top': true,
        'M - HC - Billboard - top': true
    },
    column: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true
    },
    gallery: {
        'D - Hardcoded Anchor': true,
        'M - Hardcoded Anchor': true,
        'D - In gallery - 1': true,
        'D - In gallery - 1 - alt': true,
        'D - In gallery - 2': true,
        'D - In gallery - 2 - alt': true,
        'D - In gallery - 3': true,
        'D - In gallery - 3 - alt': true,
        'M - In gallery - 1': true,
        'M - In gallery - 2': true,
        'M - In gallery - 3': true,
        'D - Floating - 1': true,
        'D - Floating - 2': true,
        'M - Floating': true,
        'D - Wallpaper - left': true,
        'D - Wallpaper - right': true,
        'D - Billboard - top': true,
        'M - Billboard - top': true,
        'D - Billboard - 1': true,
        'M - Billboard - 1': true
    }
}

const dependantZonesConfig = {
    'M - Hardcoded Anchor': ['M - Floating'],
    'D - Hardcoded Anchor': ['D - Wallpaper - left'],
    'M - Hardcoded Interstitial': ['M - Floating'],
    'D - Hardcoded Interstitial': ['D - Wallpaper - left'],
    'D - Hardcoded Wallpaper - right': ['D - Wallpaper - right'],
    'D - Hardcoded Wallpaper - left': ['D - Wallpaper - right']
}

const FB_PIXEL_ID = '864000226967467'
const GEMIUS_IDENTIFIER = process.env.NEXT_PUBLIC_GEMIUS_ID_NET
const EXPONEA_TOKEN = '590f3dcc-2414-11ef-a8fa-9ab3afd0692b'

// eslint-disable-next-line react/prop-types
function MainThemeProvider({ children, pageTheme }) {
    return (
        <LunaContext.Provider value={themesObject}>
            <Luna theme={pageTheme || currentTheme}>{children}</Luna>
        </LunaContext.Provider>
    )
}

const didomiSettings = {
    apiKey: 'c15af460-4782-467a-8d5b-88619667de76',
    noticeId: 'MFGgHpjx',
    iabVersion: 2,
    gdprAppliesGlobally: true
}

// omit Context and Provider from names since it's implied from use
// could make these even shorter via a map with auto generated names, but meh
const CombinedAppProvider = combineWrappers(
    {
        ClientNavigation: ClientNavigationContextProvider,
        ReduxStore: ReduxStoreProvider,
        QueryCache: QueryCacheProvider,
        CountryCode: CountryCodeProvider,
        Settings: SettingsProvider,
        MainTheme: MainThemeProvider,
        Navigation: NavigationContext.Provider,
        AdManifest: AdManifestContext.Provider,
        AdCallback: AdCallbacksContext.Provider,
        FilledSlots: FilledSlotsContext.Provider,
        RefreshSlots: RefreshSlotsContext.Provider,
        ExternalAds: ExternalAdsProvider,
        // PredsjednickiIzbori: PredsjednickiIzboriContextProvider,
        DidomiProvider
    },
    {
        DidomiProvider: didomiSettings
    }
)
function WebApp({ Component, pageProps }) {
    // NOTE: pageProps?.pageProps is for fake-pages/entity pages (single entities) (custom) and
    // pageProps is for all other pages (default behavior). I have done this this way because I
    // needed to return other data for single entity pages in order to make them work.
    // See fake-pages/entity/index.js for reference on why this is neeeded.
    const {
        themeName,
        ads,
        voyoSliders,
        gemiusId,
        disable = [],
        serverSideHeaders = {},
        euIzbori2024 = null,
        predsjednicki = null,
        ssrData = {}
    } = pageProps?.pageProps ?? pageProps ?? {}
    const lengthScore = ssrData?.lengthScore ?? { debug: 'page not measured yet' }
    const [activeCategory] = useActiveCategory()
    const pageCategory = activeCategory.replace('/', '')

    const pageTheme = Component.themeName ?? themeName ?? currentTheme
    return (
        <CombinedAppProvider
            value={{
                ReduxStore: { store },
                CountryCode: { value: serverSideHeaders.countryCode || null },
                Settings: { clientName: xClientName },
                MainTheme: { theme: themesObject, pageTheme },
                AdManifest: { value: ads },
                EuIzbori: { value: euIzbori2024 },
                PredsjednickiIzbori: {
                    value: {
                        results: {
                            counties: predsjednicki?.results?.counties || null,
                            cities: predsjednicki?.results?.cities || null
                        },
                        poll: predsjednicki?.poll || null
                    }
                },
                DidomiProvider: { enabled: !disable?.includes('didomi') }
            }}>
            {/* <UserAgentHistoryTrap userAgent={UserAgent.FACEBOOK} /> */}
            <IsolatedAppHooks appName={AppName.NET} voyoData={voyoSliders} />
            <Head>
                <title>{process.env.NEXT_PUBLIC_APP_NAME_NET}</title>

                <meta
                    key="google-site-verification"
                    name="google-site-verification"
                    content="0oDKxc81YjkEcCSh3xsYTGw2rj5eSEt22Fp6oA0kl4M"
                />
                {/* Use minimum-scale=1 to enable GPU rasterization */}

                <meta
                    key="viewport"
                    name="viewport"
                    content="minimum-scale=1,
                            initial-scale=1,
                            width=device-width,
                            shrink-to-fit=no"
                />
            </Head>
            <DefaultSeo {...SEO} />
            <GlobalStyles />

            <UpScoreContextProvider upScoreDomain="net.hr" />

            <Component {...pageProps} />

            {!disable?.includes('dfp') && (
                <AdsProvider
                    appName={pageVariants.NET}
                    dfpNetworkId={process.env.NEXT_PUBLIC_DFP_NETWORK_ID_NET}
                    collapseEmptyDivs
                    singleRequest
                    rubiconMagniteScriptName="dynamic/26588"
                    currentAdManifest={ads}
                    dependantZonesMap={dependantZonesConfig}
                    ignoreZonesOnShallowNavigation={ignoreZonesOnShallowNavigationConfig}
                />
            )}
            <DanasHrLogoGradient />
            {/* <TaboolaScript /> */}
            <IsolatedUseLengthScoreGTM lengthCategory={lengthScore.lengthCategory} lengthScore={lengthScore.score} />

            <TrackingScripts
                appName={AppName.NET}
                googleTagManagerId={(!disable?.includes('gtm') && process.env.NEXT_PUBLIC_GTM_NET) || undefined}
                facebookPixelId={(!disable?.includes('fb') && FB_PIXEL_ID) || undefined}
                gemiusId={(!disable?.includes('gemius') && (gemiusId ?? GEMIUS_IDENTIFIER)) || undefined}
                oneSignalAppId={
                    (!disable?.includes('onesignal') && process.env.NEXT_PUBLIC_ONESIGNAL_ID_NET) || undefined
                }
                dotmetricsCategory={(!disable?.includes('dotmetrics') && pageCategory) || undefined}
                exponeaToken={EXPONEA_TOKEN}
            />
        </CombinedAppProvider>
    )
}

WebApp.propTypes = {
    Component: PropTypes.func.isRequired,
    pageProps: PropTypes.shape({
        themeName: PropTypes.string,
        pageProps: PropTypes.shape({
            themeName: PropTypes.string,
            predsjednicki: PropTypes.shape({
                results: PropTypes.shape({
                    counties: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            name: PropTypes.string.isRequired
                        })
                    ),
                    cities: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            name: PropTypes.string.isRequired
                        })
                    )
                }),
                poll: PropTypes.shape({})
            })
        })
    }),
    router: PropTypes.shape({
        route: PropTypes.string.isRequired,
        pathname: PropTypes.string
    }).isRequired
}

WebApp.defaultProps = {
    pageProps: undefined
}

export default WebApp
