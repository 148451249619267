import PropTypes from 'prop-types'

import { IsolatedUsePersistentQueryParam } from '../../../hooks/usePersistentQueryParam'
import { AppName } from '../../../types/app/index'
import { Dotmetrics } from '../Dotmetrics'
import { Exponea } from '../Exponea'
import { FacebookPixel } from '../FacebookPixel'
// import { GemiusPixel } from '../GemiusPixel'
import { GoogleTagManager } from '../GoogleTagManager'
import { LinkerPixel } from '../LinkerPixel'
import { MidasPixel } from '../MidasPixel'
import { NativeEventListener } from '../NativeEventListener'
import { OneSignal } from '../OneSignal'

function TrackingScripts({
    appName,
    dotmetricsCategory,
    facebookPixelId,
    // gemiusId,
    googleTagManagerId,
    linkerPixelId,
    midasPixelId,
    exponeaToken,
    oneSignalAppId,
    oneSignalSafariAppId
}) {
    return (
        <>
            <NativeEventListener />
            {dotmetricsCategory && <Dotmetrics category={dotmetricsCategory} />}
            {facebookPixelId && <FacebookPixel id={facebookPixelId} />}
            {/* {gemiusId && <GemiusPixel id={gemiusId} />} */}
            {googleTagManagerId && <GoogleTagManager id={googleTagManagerId} />}
            {linkerPixelId && <LinkerPixel campaignId={linkerPixelId} />}
            {midasPixelId && <MidasPixel id={midasPixelId} />}
            {oneSignalAppId && <OneSignal appId={oneSignalAppId} safariAppId={oneSignalSafariAppId} />}
            {exponeaToken && <Exponea token={exponeaToken} />}
            <IsolatedUsePersistentQueryParam appName={appName} queryKey="cypressAdsDebug" />
        </>
    )
}

TrackingScripts.propTypes = {
    appName: PropTypes.oneOf(Object.values(AppName)).isRequired,
    dotmetricsCategory: PropTypes.string,
    facebookPixelId: PropTypes.string,
    // gemiusId: PropTypes.string,
    googleTagManagerId: PropTypes.string,
    linkerPixelId: PropTypes.string,
    midasPixelId: PropTypes.string,
    oneSignalAppId: PropTypes.string,
    oneSignalSafariAppId: PropTypes.string,
    exponeaToken: PropTypes.string
}

TrackingScripts.defaultProps = {
    dotmetricsCategory: 'ostalo',
    facebookPixelId: undefined,
    // gemiusId: undefined,
    googleTagManagerId: undefined,
    linkerPixelId: undefined,
    midasPixelId: undefined,
    oneSignalAppId: undefined,
    oneSignalSafariAppId: undefined,
    exponeaToken: undefined
}

// @NOTE: Can't use next/script here
export function HeadTrackingScripts() {
    // EchoBox automatic sharing or something
    return <script async id="ebx" src="//applets.ebxcdn.com/ebx.js" />
}
HeadTrackingScripts.propTypes = {}
HeadTrackingScripts.defaultProps = {}

export default TrackingScripts
